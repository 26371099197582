import '../scss/index.scss';
import $ from 'jquery';

$(document).ready(function () {
    // Hero height and spacing
    $('.page').css('margin-top', $('header').outerHeight());
    $('.studio-placement-wrapper').css('top', $('header').outerHeight() * -1);
    $(window).on('resize', function () {
        $('.page').css('margin-top', $('header').outerHeight());
        $('.studio-placement-wrapper').css(
            'top',
            $('header').outerHeight() * -1
        );
    });

    const heroResize = function () {
        var width = $(window).width();
        var height = width * 0.65;
        // Work page
        var workHeight = width * 0.48;
        $('#work-page .page').height(workHeight);
        $('#work-page .page .tagline').css('padding-top', height * 0.3);
        // About and contact page
        var aboutHeight = width * 0.35;
        $('#about-page .page, #contact-page .page').height(aboutHeight);
        $('#about-page .page .tagline, #contact-page .page .tagline').css(
            'padding-top',
            height * 0.1
        );
    };
    heroResize();
    window.onresize = function () {
        heroResize();
    };

    // Mobile menu
    var buttonCount = 0;

    $.extend($.easing, {
        easeOutQuart: function (x, t, b, c, d) {
            return -c * ((t = t / d - 1) * t * t * t - 1) + b;
        },
    });

    $('#mobileMenuButton').click(function () {
        $(this).toggleClass('open');
        if (buttonCount % 2 === 0) {
            $('#navigation').animate(
                { 'margin-top': '10px' },
                500,
                'easeOutQuart'
            );
        } else {
            $('#navigation').animate(
                { 'margin-top': '-1000px' },
                500,
                'easeOutQuart'
            );
        }
        buttonCount++;
    });
});
